<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Newsletters</h4>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="tableData.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5 text-center">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-body text-left">
            <b-table
              :items="tableData"
              :fields="fields"
              responsive
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              caption-top
              hover
              search
              head-variant="light"
            >
              <template #cell(created_at)="data">
                {{ data.item.created_at | moment('DD/MM/YYYY') }}
              </template>
              <template #cell(action)="data">
                <a
                  v-b-tooltip.hover.top
                  title="Remover"
                  href="javascript: void(0);"
                  @click.prevent="removerItem(data.item)"
                >
                  <i class="fas fa-trash-alt text-danger ml-4"></i>
                </a>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-5" v-if="loadingbtn && tableData.length > 0">
      <div class="col-12 text-center">
        <b-button variant="info" @click.prevent="carregarMais()">
          <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
          Carregar Mais
        </b-button>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    Multiselect,
    InfiniteLoading,
  },
  data() {
    return {
      sortBy: 'created_at',
      sortDesc: false,
      fields: [
        {
          key: 'name',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'email',
          label: 'E-mail',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Data',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Ação',
          sortable: false,
        },
      ],

      tableData: [],
      filtro: {
        page: 1,
        limit: 10,
      },
      loading: true,
      error: false,
      loadingbtn: true,
    }
  },
  created() {},
  methods: {
    ...mapActions('newsletter', [
      'ActionNewsletterAll',
      'ActionNewsletterDelete',
    ]),
    async carregarMais() {
      try {
        this.loadingbtn = true
        await this.ActionNewsletterAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.tableData.push(...res.data.data.list)
          } else {
            this.loadingbtn = false
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.tableData = []
        this.loading = false
        this.error = true
      }
    },
    async infiniteHandler($state) {
      try {
        this.loadingbtn = true
        await this.ActionNewsletterAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.tableData.push(...res.data.data.list)
            $state.loaded()
          } else {
            this.loadingbtn = false
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.tableData = []
        $state.complete()
      }
    },
    async listPost() {
      try {
        this.filtro.page = 1
        this.loadingbtn = true
        await this.ActionNewsletterAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page = 2
            this.tableData = res.data.data.list
          } else {
            this.loadingbtn = false
            this.tableData = []
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.tableData = []
      }
    },
    removerItem(data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(data)
        }
      })
    },
    async removerItemUp(data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        await this.ActionNewsletterDelete(data.id)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.tableData.splice(this.tableData.indexOf(data), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
